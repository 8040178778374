<template>
  <div class="inputText">
    <FormKit
      :id="id"
      v-model="value"
      :type="'password'"
      :name="name ?? id"
      :label="t(label)"
      :placeholder="t('formFields.password.placeholder')"
      input-class="input"
      :outer-class="'mb-sm'"
      :validation-visibility="validationVisible ? 'live' : 'dirty'"
      :validation-messages="{
        required: t(requiredMessage),
        confirm: t('formFields.password.confirmError'),
      }"
      :validation="getValidationRules()"
      label-class="text-md !leading-[19px] font-bold"
      inner-class="pt-[5px] relative': true"
      :messages-class="'!bg-vdv-bg-base px-sm py-[2px] text-sign-red'"
      @node="(n: FormKitNode) => (node = n)"
      @focus="() => (focus = true)"
      @blur="() => (focus = false)"
    >
      <template #messages="{ messages }">
        <div
          v-if="
            messages['rule_matches'] ||
            messages['rule_required'] ||
            messages['rule_confirm'] ||
            (focus && !isValid && !confirmField)
          "
          class="bg-vdv-bg-base px-sm py-[2px]"
        >
          <div
            v-if="
              messages['rule_matches'] || (focus && !isValid && !confirmField)
            "
          >
            <div>
              <div class="font-bold">
                <span>
                  {{
                    t('formFields.password.validation.strength.label')
                  }} </span
                >:
                <span
                  :class="{
                    'text-sign-green': isValid,
                    'text-sign-red': !isValid,
                  }"
                >
                  {{
                    t(
                      isValid
                        ? 'formFields.password.validation.strength.secure'
                        : 'formFields.password.validation.strength.weak',
                    )
                  }}
                </span>
              </div>
              <div
                v-for="condition in passwordConditions"
                :key="condition.name"
                class="flex flex-row items-center gap-xs"
              >
                <div>
                  <FaIcon
                    v-if="condition.valid.value"
                    classes="text-sign-green"
                    icon-class="fas fa-circle-check"
                  />
                  <FaIcon
                    v-else
                    icon-class="fas fa-circle-xmark"
                    classes="text-sign-red"
                  />
                </div>
                <div>{{ t(condition.token) }}</div>
              </div>
            </div>
          </div>
          <div v-if="messages['rule_required']" class="text-sign-red">
            {{ t(requiredMessage) }}
          </div>
          <div v-if="messages['rule_confirm']" class="text-sign-red">
            {{ t('formFields.password.validation.confirmError') }}
          </div>
        </div>
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import type { FormKitNode } from '@formkit/core';
import FaIcon from '~~/src/components/fa-icon.vue';
import { FormKit } from '@formkit/vue';

const { t } = useTrans();
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    default: undefined,
  },
  requiredMessage: {
    type: String,
    default: '',
  },
  confirmField: {
    type: String,
    default: '',
  },
  validationVisible: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'change'): void;
}>();

onMounted(() => {
  const input: HTMLElement = document.querySelector(
    `input[name='${props.name}']`,
  );
  if (props.autofocus) {
    input.focus();
  }
});

const validRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&(){}[\]:;<>,.?\/~_+\-=|\\]).{12,50}$/;

function getValidationRules() {
  const rules: [string, ...any][] = [['required']];

  if (props.confirmField) {
    rules.push(['confirm', props.confirmField]);
  } else {
    rules.push(['matches', validRegex]);
  }

  return rules;
}

const node = ref<FormKitNode | null>();
const focus = ref<boolean>(false);
const value = ref<string>('');

watch(value, () => {
  emit('change');
});

const isValid = computed<boolean>(() => {
  return validRegex.test(value.value);
});

const passwordConditions = [
  {
    name: 'length',
    token: 'formFields.password.validation.condition.length',
    valid: computed<boolean>(() => {
      return value.value.length >= 12 && value.value.length <= 50;
    }),
  },
  {
    name: 'lowercase',
    token: 'formFields.password.validation.condition.lowercase',
    valid: computed<boolean>(() => {
      return /[a-z]/g.test(value.value);
    }),
  },
  {
    name: 'uppercase',
    token: 'formFields.password.validation.condition.uppercase',
    valid: computed<boolean>(() => {
      return /[A-Z]/g.test(value.value);
    }),
  },
  {
    name: 'special',
    token: 'formFields.password.validation.condition.special',
    valid: computed<boolean>(() => {
      return /[!@#$%^&(){}[\]:;<>,.?\/~_+\-=|\\]/g.test(value.value);
    }),
  },
];
</script>
<style lang="postcss" scoped>
.inputText :deep(input.input) {
  @apply border-l-1 border-r-1 border-t-1 outline-0 border-b-[3px] h-9 px-2 md:px-4 pt-2 text-md text-vdv-font ring-0 focus:border-b-[3px] shadow-none z-100;
  @apply focus:border-b-vdv-base  border-vdv-border-light;
  @apply w-full;
}

.inputText :deep([data-invalid='true']) {
  input.input {
    @apply !border-b-sign-red;
  }
}
</style>
